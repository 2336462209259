import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", { attrs: { "title": "Edit Global Portfolio", "has-last-modified": false, "is-fetch-entity-by-id": false, "has-deactivate-button": false } }, [_c("text-input", { key: "gPortfolio", attrs: { "label": "Global Portfolio", "placeholder": "Type Global Portfolio", "rules": "required", "form-item": "", "span": 12 } }), _c("select-input", { key: "gpGroupId", attrs: { "label": "Global Portfolio Group", "reference": "web-analytics.common.ppv-portfolio-group", "placeholder": "Select Global Portfolio Group", "source": "gpGroupId", "source-label": "gpGroupName", "rules": "required", "span": 10, "form-item": "" } })], 1);
};
var staticRenderFns$1 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "EditGlobalPortfolios",
  inject: ["crud"],
  data() {
    return {
      apiUrl: apiUrl$1,
      visible: false,
      isLoading: false,
      GPortfolio: "",
      GPGroupId: ""
    };
  },
  computed: {
    entity() {
      return this.crud.getEntity();
    }
  },
  mounted() {
    this.visible = true;
  },
  created() {
    this.storeValue("UpdateType", "update");
  },
  methods: {
    storeValue(resourceKey, value) {
      this.editForm = {
        ...this.editForm,
        [resourceKey]: value
      };
      this.crud.updateEntity("editForm", {
        resourceKey,
        value
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditGlobalPortfolios = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "web-analytics.common.ppv-portfolio-group", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.global-portfolios", "api-url": _vm.apiUrl, "redirect-route": "/web-analytics/global-portfolios" } }, [_c("edit-global-portfolios")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditGlobalPortfolios
  },
  data() {
    return {
      EditGlobalPortfolios,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
